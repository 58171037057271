import { ComponentType } from 'react';
import { useAuthentication } from './useAuthentication';
import { useModules } from './useModules';
import { Suggestion } from './useSuggestions';

export const NOTE_CUSTOM_RANGE_CODE = 'note_custom_range';
export const LAB_DATA_CUSTOM_RANGE_CODE = 'lab_data_custom_range';
export const VITAL_SIGN_CUSTOM_RANGE_CODE = 'vital_sign_custom_range';
export const NOTE_TYPE = 'note';
export const LAB_DATA_TYPE = 'lab_data';
export const VITAL_SIGN_TYPE = 'vital_sign';
export const MEDICATION_TYPE = 'medication';
export const PROBLEM_LIST_TYPE = 'problem_list';
export const DEMOGRAPHICS_TYPE = 'demographics';

// Radiology reports
export const RADIOLOGY_REPORT_TYPE = 'radiology_report';
export const RADIOLOGY_REPORT_CUSTOM_RANGE_CODE = 'radiology_report_custom_range';
export const RADIOLOGY_REPORT_SUGGESTION: Suggestion[] = [
  { name: 'Radiology Reports (EHR)', code: 'heading', type: RADIOLOGY_REPORT_TYPE },
];
export const radiologyReports: Suggestion[] = [
  {
    id: 1,
    name: 'Last 24 hours',
    code: 'radiology_report_recent_1_day',
    type: RADIOLOGY_REPORT_TYPE,
  },
  {
    id: 2,
    name: 'Last 3 months',
    code: 'radiology_report_recent_90_days',
    type: RADIOLOGY_REPORT_TYPE,
  },
  {
    id: 3,
    name: 'Last 6 months',
    code: 'radiology_report_recent_180_days',
    type: RADIOLOGY_REPORT_TYPE,
  },
  {
    id: 4,
    name: 'Last 1 year',
    code: 'radiology_report_recent_365_days',
    type: RADIOLOGY_REPORT_TYPE,
  },
  {
    id: 6,
    name: 'Custom Range',
    code: RADIOLOGY_REPORT_CUSTOM_RANGE_CODE,
    type: RADIOLOGY_REPORT_TYPE,
  },
];

// TODO: Remove parameter when suggestion is not used anymore
export const useRadiologyReportValue = (
  suggestion: Suggestion[] = [],
  enabled = true
): Suggestion[] => {
  return enabled ? [...suggestion, ...radiologyReports] : [];
};

// demographics
export const DEMOGRAPHICS_SUGGESTION: Suggestion[] = [
  { name: 'Demographics (EHR)', code: 'heading', type: DEMOGRAPHICS_TYPE },
];
export const demographics: Suggestion[] = [
  {
    id: 1,
    name: 'All',
    code: 'demographics_all',
    type: DEMOGRAPHICS_TYPE,
  },
  {
    id: 2,
    name: 'Sex',
    code: 'demographics_sex',
    type: DEMOGRAPHICS_TYPE,
  },
  {
    id: 3,
    name: 'Age',
    code: 'demographics_age',
    type: DEMOGRAPHICS_TYPE,
  },
  {
    id: 4,
    name: 'Race',
    code: 'demographics_race',
    type: DEMOGRAPHICS_TYPE,
  },
];

// problem list
export const PROBLEM_LIST_SUGGESTION: Suggestion[] = [
  { name: 'Problem List (EHR)', code: 'heading', type: PROBLEM_LIST_TYPE },
];
export const PROBLEM_LIST_CODE = 'problem_list';
export const problemLists: Suggestion[] = [
  {
    id: 1,
    name: 'Problem list',
    code: PROBLEM_LIST_CODE,
    type: PROBLEM_LIST_TYPE,
  },
];

// medications
export const MEDICATION_SUGGESTION: Suggestion[] = [
  { name: 'Medications (EHR)', code: 'heading', type: MEDICATION_TYPE },
];
export const MEDICATION_CODE = 'amount';
export const medications: Suggestion[] = [
  {
    id: 1,
    name: 'Maximum 100 medications',
    code: MEDICATION_CODE,
    amount: 100,
    type: MEDICATION_TYPE,
  },
];

// notes
export const SUGGESTION: Suggestion[] = [{ name: 'Notes (EHR)', code: 'heading', type: 'note' }];
const notes: Suggestion[] = [
  {
    id: 1,
    name: 'Last 24 hours',
    code: 'note_recent_1_day',
    type: NOTE_TYPE,
  },
  {
    id: 2,
    name: 'Last 3 months',
    code: 'note_recent_90_days',
    type: NOTE_TYPE,
  },
  {
    id: 3,
    name: 'Last 6 months',
    code: 'note_recent_180_days',
    type: NOTE_TYPE,
  },
  {
    id: 4,
    name: 'Last 1 year',
    code: 'note_recent_365_days',
    type: NOTE_TYPE,
  },
  {
    id: 5,
    name: 'Most recent progress note',
    code: 'most_recent_progress_note',
    type: NOTE_TYPE,
  },
  {
    id: 6,
    name: 'Custom Range',
    code: NOTE_CUSTOM_RANGE_CODE,
    type: NOTE_TYPE,
  },
];

// TODO: Remove parameter when suggestion is not used anymore
export const useNotesValue = (suggestion: Suggestion[] = [], enabled = true): Suggestion[] => {
  const { authentication } = useAuthentication();
  return enabled
    ? [
        ...suggestion,
        ...notes.filter((item) =>
          !authentication.user?.is_staff ? item.id !== 1 && item.id !== 6 : true
        ),
      ]
    : [];
};

// HOC for class components
interface NotesProps {
  notes: Suggestion[];
}

// lab data hook & HOC
export const LAB_DATA_SUGGESTION: Suggestion[] = [
  { name: 'Lab Data (EHR)', code: 'heading', type: 'lab_data' },
];
const LabData: Suggestion[] = [
  {
    id: 1,
    name: 'Last 24 hours',
    code: 'lab_data_recent_1_day',
    type: LAB_DATA_TYPE,
  },
  {
    id: 2,
    name: 'Last 3 months',
    code: 'lab_data_recent_90_days',
    type: LAB_DATA_TYPE,
  },
  {
    id: 3,
    name: 'Last 6 months',
    code: 'lab_data_recent_180_days',
    type: LAB_DATA_TYPE,
  },
  {
    id: 4,
    name: 'Last 1 year',
    code: 'lab_data_recent_365_days',
    type: LAB_DATA_TYPE,
  },
  {
    // it is intended id is 6, not 5 as it needs to be divided when display in tiptap editor, preview section in MentionNode.tsx
    id: 6,
    name: 'Custom Range',
    code: LAB_DATA_CUSTOM_RANGE_CODE,
    type: LAB_DATA_TYPE,
  },
];

export const useLabDataValue = (suggestion: Suggestion[] = [], enabled = true) => {
  const { authentication } = useAuthentication();
  return enabled
    ? [
        ...suggestion,
        ...LabData.filter((item) =>
          !authentication.user?.is_staff ? item.id !== 1 && item.id !== 6 : true
        ),
      ]
    : [];
};

// vital sign hook
export const VITAL_SIGN_SUGGESTION: Suggestion[] = [
  { name: 'Vital Signs (EHR)', code: 'heading', type: 'vital_sign' },
];
const VitalSigns: Suggestion[] = [
  {
    id: 1,
    name: 'Last 24 hours',
    code: 'vital_sign_recent_1_day',
    type: VITAL_SIGN_TYPE,
  },
  {
    id: 2,
    name: 'Last 3 months',
    code: 'vital_sign_recent_90_days',
    type: VITAL_SIGN_TYPE,
  },
  {
    id: 3,
    name: 'Last 6 months',
    code: 'vital_sign_recent_180_days',
    type: VITAL_SIGN_TYPE,
  },
  {
    id: 4,
    name: 'Last 1 year',
    code: 'vital_sign_recent_365_days',
    type: VITAL_SIGN_TYPE,
  },
  {
    // it is intended id is 6, not 5 as it needs to be divided when display in tiptap editor, preview section in MentionNode.tsx
    id: 6,
    name: 'Custom Range',
    code: VITAL_SIGN_CUSTOM_RANGE_CODE,
    type: VITAL_SIGN_TYPE,
  },
];

export const useVitalSignsValue = (suggestion: Suggestion[] = [], enabled = true) => {
  const { authentication } = useAuthentication();
  return !!authentication.user?.is_staff && enabled ? [...suggestion, ...VitalSigns] : [];
};

interface EHRVariablesProps {
  notes: Suggestion[];
  labData: Suggestion[];
  vitalSigns: Suggestion[];
}

export const withEHRVariables = <P extends object>(
  WrappedComponent: ComponentType<P & EHRVariablesProps>,
  noteSuggestion: Suggestion[] = [],
  labDataSuggestion: Suggestion[] = [],
  vitalSignsSuggestion: Suggestion[] = [],
  medicationSuggestion: Suggestion[] = [],
  problemListSuggestion: Suggestion[] = [],
  demographicsSuggestion: Suggestion[] = [],
  radiologyReportsSuggestion: Suggestion[] = []
) => {
  return (props: P) => {
    const hasEHRVariables = props['hasEHRVariables'] ?? true;
    const notes = useNotesValue(noteSuggestion, hasEHRVariables);
    const labData = useLabDataValue(labDataSuggestion, hasEHRVariables);
    const vitalSigns = useVitalSignsValue(vitalSignsSuggestion, hasEHRVariables);
    const radiologyReports = useRadiologyReportValue(radiologyReportsSuggestion, hasEHRVariables);
    return (
      <WrappedComponent
        notes={notes}
        labData={labData}
        vitalSigns={vitalSigns}
        medications={[...medicationSuggestion, ...medications]}
        problemList={[...problemListSuggestion, ...problemLists]}
        demographics={[...demographicsSuggestion, ...demographics]}
        radiologyReports={radiologyReports}
        {...props}
      />
    );
  };
};

const KB_DATA_SUGGESTION = [{ name: 'Knowledge Base', code: 'heading', type: 'knowledge_base' }];

export const useKnoweledgeBase = () => {
  const { modules } = useModules();
  const knowledgeBases = modules.filter(
    (module) => module.type === 'knowledge_base' || module.mirrorOf === 'knowledge_base'
  );

  // Draftjs suggestions format
  const kbSuggestions = knowledgeBases.map((kb) => ({
    name: kb.title,
    code: kb.code,
    type: 'knowledge_base',
  }));

  return [...KB_DATA_SUGGESTION, ...kbSuggestions];
};

export const withKnowledgeBase = <P extends object>(WrappedComponent: ComponentType<P>) => {
  return (props: P) => {
    const knowledgeBases = useKnoweledgeBase();
    return <WrappedComponent knowledgeBases={knowledgeBases} {...props} />;
  };
};
