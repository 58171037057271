import { useMutation } from '@tanstack/react-query';
import { Editor } from '@tiptap/react';
import { getTriggers } from 'actions/resources/getTriggers';
import { DataExtractorFormVariable, dataExtractorAPI } from 'api/data-extractor';
import { ComboBox } from 'components/utils/ComboBox';
import Input from 'components/utils/Input';
import { ConfirmModal } from 'components/utils/modals/ConfirmModal';
import { Slide } from 'components/utils/panels/FloatingSlidePanel';
import { Header } from 'components/utils/panels/Header';
import RequiredMark from 'components/utils/requiredMark';
import {
  RichTextMenuButtonTypes,
  SuggestionTypeEnum,
} from 'components/utils/tiptap/tiptapInterfaces';
import { CustomToast } from 'components/utils/toast-message';
import { Body2 } from 'components/utils/typo';
import VariableAutoGenerator from 'components/utils/variable-generator';
import { CARD_DATA_EXTRACTOR } from 'constants/variables';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { getVariableName } from 'utils/gptUtils';
import { MODULE_TYPES } from '../../../constants';
import { useDataExtractorFormContext } from '../../module/cards/DataExtractorCard';
import SituationBuilder from '../../resources/triggers/SituationBuilder';
import { FormTooltip } from '../../utils/helpInfo/FormTooltip';
import { MentionProvider } from '../../utils/module/MentionContext';
import { Footer } from '../../utils/panels/Footer';
import { Tiptap } from '../../utils/tiptap/Tiptap';
import { DataToConsumeListInput } from './data-to-consume/DataToConsumeListInput';
import { VariablesToGenerateController } from './variable-to-generate/VariableToGenerateController';

interface DataExtractorFormProps {
  modal: boolean;
  onSave: () => void;
  closeModal: (type?: any) => void;
  closePanel: (command?: string) => void;
}

const richTextButtonsShowList: RichTextMenuButtonTypes[] = [
  'textStyles',
  'bold',
  'italic',
  'highlight',
  'bulletList',
  'orderedList',
  'more',
];

export const DataExtractorForm = ({
  modal,
  onSave,
  closeModal,
  closePanel,
}: DataExtractorFormProps) => {
  // for trigger panel
  const [triggerPanelOpen, setTriggerPanelOpen] = useState(false);
  const [triggerModalOpen, setTriggerModalOpen] = useState(false);
  const { moduleId } = useParams<{ moduleId: string }>();
  const [triggerId, setTriggerId] = useState<string | number>('');
  const triggerComponentProps = {
    toggleModal: () => setTriggerPanelOpen(false),
    isFullHeight: true,
    selectTrigger: (value) => setTrigger(value),
    createModal: true,
    modal: triggerModalOpen,
    triggerId: triggerId,
    savePosition: () => {},
    toogleModalPane: () => setTriggerModalOpen(false),
  };

  // TODO: remove when redux data is depracated
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTriggers(MODULE_TYPES.ALGO, null, undefined, moduleId));
  }, []);

  const [isVarLoading, setVarLoading] = useState(false);

  const { watch, register, handleSubmit, setValue, reset, control } = useDataExtractorFormContext();
  const title = watch('title');
  const description = watch('description');

  const { mutate, isLoading: isMutating } = useMutation(dataExtractorAPI.upsertDataExtractor, {
    onSuccess: (data) => {
      onSave();
      reset(data);
    },
  });

  const onKeyDown = (e: any) => {
    if (e.key === 'Enter' && e.target.nodeName !== 'TEXTAREA') {
      e.preventDefault();
    }
  };

  const setVariableName = (variable: string) => {
    setValue('variable_name', variable);
  };

  const setTrigger = (value) => {
    setValue('trigger', value);
  };

  const onSubmit = async (data: DataExtractorFormVariable) => {
    if (isMutating) return;

    if (isDisabled) {
      toast.error(CustomToast, { data: 'Please fill out the title field.' });
    }

    if (!data.variable_name) {
      const variable_name = await getVariableName(title);
      mutate({ ...data, variable_name });
    } else {
      mutate(data);
    }
  };

  const triggerState = useSelector((state: any) => state.triggerState);
  const triggers = [...triggerState.triggers, ...triggerState.candidate_triggers];
  const triggerOptions = triggers.map((trigger) => ({
    value: trigger.id,
    label: trigger.title,
    candidate_trigger: trigger.candidate_trigger,
  }));

  const variablesToGenerate = watch('variables_to_generate');
  const [isInValidDataToConsume, setIsInValidDataToConsume] = useState(true);
  const [isDescriptionEmpty, setIsDescriptionEmpty] = useState(
    Object.keys(description).length === 0
  );
  const isDisabled =
    !title || isDescriptionEmpty || variablesToGenerate.length < 1 || isInValidDataToConsume;

  return (
    <div className='h-full px-[16px]'>
      <Header
        className='sticky top-0 z-10 bg-white !px-0 !pr-3 pt-[20px]'
        title={CARD_DATA_EXTRACTOR}
        toggleModal={closePanel}
      />
      <form
        autoComplete='off'
        onKeyDown={onKeyDown}
        onSubmit={handleSubmit(onSubmit)}
        className='flex h-full flex-col pb-0'
      >
        <div className='space-y-[5px]'>
          <div className='flex gap-1'>
            <Body2 className='text-gray-900'>Trigger</Body2>
            <RequiredMark />
          </div>
          <ComboBox
            onChange={setTrigger}
            options={triggerOptions}
            selectedValue={watch('trigger')}
            onCreate={() => {
              setTriggerId('');
              setTriggerPanelOpen(true);
            }}
            onEdit={(id) => {
              setTriggerId(id);
              setTriggerPanelOpen(true);
            }}
            createButtonLabel='Create New Trigger'
          />
        </div>

        <div className='!mt-8'>
          <div className='space-y-[4px]'>
            <div className='flex gap-1'>
              <Body2 className='text-gray-900'>Title</Body2>
              <RequiredMark />
            </div>
            <Input
              tabIndex={1}
              required
              maxLength={200}
              placeholder='e.g. AI Clinical Document Analyzer'
              {...register('title', {
                maxLength: 200,
                required: true,
              })}
            />
            <div className='text-right text-[12px] text-gray-700'>{title.length}/200</div>
          </div>
        </div>

        <div className='!mt-3'>
          <div className='space-y-[5px]'>
            <div className='flex gap-1'>
              <Body2 className='text-gray-900'>Function Description</Body2>
              <RequiredMark />
            </div>
            <MentionProvider
              excludedSuggestionTypes={[SuggestionTypeEnum.DATA_EXTRACTOR]}
              performChoiceCodeSubstitution
            >
              <Tiptap
                onUpdate={(editor: Editor) => {
                  setIsDescriptionEmpty(!editor.getText().trim().length);
                  setValue('description', editor.getJSON());
                }}
                initialContent={description as any}
                placeholder={
                  'Write a prompt. i.e Extract valuable information from clinical documents presented here.'
                }
                maxCharacters={500}
                maxLines={9999}
                richTextButtonsShowList={richTextButtonsShowList}
                wrapperClassNames='max-h-[600px] min-h-[280px] !h-auto'
              />
            </MentionProvider>
          </div>
        </div>

        <div className='!mb-6 !mt-3'>
          <div className='space-y-[8px]'>
            <div className='flex gap-1'>
              <Body2 className='text-gray-900' draggable={true}>
                Data to Consume
              </Body2>
              <RequiredMark />
              <FormTooltip message='Data Extractor' />
            </div>
            <div className='space-y-1'>
              <DataToConsumeListInput onDisable={setIsInValidDataToConsume} />
            </div>
          </div>
        </div>

        <div className='!mb-6 !mt-3'>
          <div className='space-y-[8px]'>
            <div className='flex gap-1'>
              <Body2 className='text-gray-900'>Variables to Generate</Body2>
              <RequiredMark />
            </div>
            <VariablesToGenerateController />
          </div>
        </div>

        <VariableAutoGenerator
          question={title}
          tagTitle='Data Extractor'
          variable={watch('variable_name')}
          setVariableName={setVariableName}
        />

        <div className='h-[100px] w-full bg-white' />
        <Footer disabled={isDisabled} onClose={closePanel} isLoading={isVarLoading || isMutating} />
      </form>
      <ConfirmModal
        preset='unsaved'
        open={modal!}
        toggleModal={closeModal}
        toggleModalPanel={closePanel}
        handleSubmit={handleSubmit(onSubmit)}
        panelForm
      />

      <Slide open={triggerPanelOpen} onOpenChange={() => setTriggerModalOpen(true)}>
        <Slide.Panel className='p-[16px]'>
          <SituationBuilder {...triggerComponentProps} />
        </Slide.Panel>
      </Slide>
    </div>
  );
};
