import { Editor } from '@tiptap/core';
import { Suggestion } from '../../../hooks/useSuggestions';

export type TableActionTypes =
  | 'AddColumnBefore'
  | 'AddColumnAfter'
  | 'DeleteColumn'
  | 'AddRowBefore'
  | 'AddRowAfter'
  | 'DeleteRow'
  | 'DeleteTable'
  | 'SplitCell'
  | 'ToggleHeaderColumn'
  | 'ToggleHeaderRow'
  | 'ToggleHeaderCell';

export type RichTextMenuButtonTypes =
  | 'textStyles'
  | 'bold'
  | 'italic'
  | 'highlight'
  | 'strike'
  | 'code'
  | 'clearMarks'
  | 'clearNodes'
  | 'paragraph'
  | 'bulletList'
  | 'orderedList'
  | 'codeBlock'
  | 'blockquote'
  | 'hr'
  | 'hardBreak'
  | 'undo'
  | 'redo'
  | 'more'
  | 'table';

export interface KnowledgeBaseSavePayload {
  text_json: string;
  markdown: string;
}

export enum SuggestionTypeEnum {
  // only use when selecting algo link on editor popover
  ALGO = 'algo',
  MODULE = 'module',
  CALCULATOR = 'calculator',
  KNOWLEDGE_BASE = 'knowledge_base',
  VARIABLE = 'variable',
  CONDITIONAL_TEXT = 'conditional_text',
  INFOBOX = 'infobox',
  MEDIA = 'media',
  LINK = 'link',
  REFERENCE = 'reference',
  CONTACT_NUMBER = 'contact_number',
  EHR_DATA = 'ehr_data',
  EHR_ORDER = 'ehr_order',
  NOTE = 'note', // NOTE_TYPE,
  LAB_DATA = 'lab_data', // LAB_DATA_TYPE,
  VITAL_SIGN = 'vital_sign', // VITAL_SIGN_TYPE,
  MEDICATION = 'medication', // MEDICATION_TYPE,
  PROBLEM_LIST = 'problem_list', // PROBLEM_LIST_TYPE,
  FORMULA = 'formula',
  NUMBER = 'number',
  CHOICE_VARIABLE = 'choice_variable',
  MULTI_VARIABLE = 'multi_variable',
  GPTBOX_VARIABLE = 'gptbox_variable',
  TEXT_INPUT_VARIABLE = 'text_input_variable',
  MESSAGE_VARIABLE = 'message_variable',
  AMBIENT_VARIABLE = 'ambient_variable',
  DATA_EXTRACTOR = 'data_extractor',
  DEMOGRAPHICS_TYPE = 'demographics',
  REDIOLOGY_REPORT_TYPE = 'radiology_report',
}

// Type derived from enum
export type SuggestionTypes = `${SuggestionTypeEnum}`;

export interface TiptapAddVariableProps {
  open: boolean;
  editor: Editor;
  onClose: () => void;
  options: Suggestion[];
  direction: 'left' | 'right' | 'partial-left';
}

export interface NumberFormattingOptions {
  [key: string]: string;
}

export type VariableTypes =
  | 'choice_variable'
  | 'multi_variable'
  | 'gptbox_variable'
  | 'apibox_variable'
  | 'text_input_variable'
  | 'message_variable'
  | 'ambient_variable'
  | SuggestionTypeEnum.DATA_EXTRACTOR
  | 'variable'
  | 'formula'
  | 'number';

export interface TiptapVariableTypes {
  id: VariableTypes;
  title: string;
}
