import { ComboBox } from 'components/utils/ComboBox';
import DropdownSingle from 'components/utils/common/DropdownSingle';
import { StaffOnlyBadgeType, StaffOnlyWrapper } from 'components/utils/StaffOnlyWrapper';
import { SuggestionTypes } from 'components/utils/tiptap/tiptapInterfaces';
import { useContext, useEffect, useMemo, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import {
  DEMOGRAPHICS_TYPE,
  LAB_DATA_CUSTOM_RANGE_CODE,
  LAB_DATA_TYPE,
  MEDICATION_CODE,
  MEDICATION_TYPE,
  NOTE_CUSTOM_RANGE_CODE,
  NOTE_TYPE,
  PROBLEM_LIST_CODE,
  PROBLEM_LIST_TYPE,
  RADIOLOGY_REPORT_CUSTOM_RANGE_CODE,
  RADIOLOGY_REPORT_TYPE,
  VITAL_SIGN_CUSTOM_RANGE_CODE,
  VITAL_SIGN_TYPE,
} from '../../../../../hooks/useEHRVariables';
import Checkbox from '../../../Checkbox';
import Label from '../../../Label';
import { MentionContext } from '../../../module/MentionContext';
import { Body1, Caption2 } from '../../../typo';
import { SuggestionHandlerProps } from '../MentionPopover';

export const DEFAULT_AMOUNT_BY_TYPES = {
  [NOTE_TYPE]: 20,
  [LAB_DATA_TYPE]: 200,
  [VITAL_SIGN_TYPE]: 40,
  [MEDICATION_TYPE]: 100,
  [RADIOLOGY_REPORT_TYPE]: 60,
};

export const MAXIMUM_AMOUNT_BY_TYPES = {
  [NOTE_TYPE]: 300,
  [LAB_DATA_TYPE]: 4000,
  [VITAL_SIGN_TYPE]: 4000,
  [MEDICATION_TYPE]: Infinity,
  [RADIOLOGY_REPORT_TYPE]: 1000, // TODO: TBD
};

export const READABLE_EHR_TYPES = {
  [NOTE_TYPE]: 'notes',
  [LAB_DATA_TYPE]: 'labs',
  [VITAL_SIGN_TYPE]: 'vital signs',
  [MEDICATION_TYPE]: 'medications',
  [RADIOLOGY_REPORT_TYPE]: 'radiology reports',
};

export const EHRDataHandler = ({ onChange, onDisabledChange }: SuggestionHandlerProps) => {
  const { suggestions } = useContext(MentionContext);

  const filteredHeadings = useMemo(
    () =>
      suggestions.filter((suggestion) =>
        [
          NOTE_TYPE,
          LAB_DATA_TYPE,
          VITAL_SIGN_TYPE,
          MEDICATION_TYPE,
          PROBLEM_LIST_TYPE,
          DEMOGRAPHICS_TYPE,
          RADIOLOGY_REPORT_TYPE,
        ].includes(suggestion.type)
      ),
    [suggestions]
  );

  const [variable, setVariable] = useState<string>('');
  const [variableType, setVariableType] = useState<string>('');
  const [hasUnsignedNote, setHasUnsignedNote] = useState<boolean>(false);
  const [customDay, setCustomDay] = useState<number>();
  const [amount, setAmount] = useState<number>();
  const [past24HoursExcluded, setPast24HoursExcluded] = useState(false);

  const variableOptions = useMemo(() => {
    const varOptions = filteredHeadings.filter((option) => option.type === variableType);
    return varOptions.map((option) => ({ value: option.code, label: option.name }));
  }, [variableType, filteredHeadings]);

  const filteredVariableTypes = useMemo(() => {
    return EHR_TYPES.filter(
      (type) =>
        type.id === MEDICATION_TYPE ||
        type.id === PROBLEM_LIST_TYPE ||
        filteredHeadings.some((option) => option.type === type.id)
    ).map((ehrType) => ({
      id: ehrType.id,
      title: ehrType.title,
    }));
  }, [filteredHeadings]);

  const changeVariableType = (value: string) => {
    const variable =
      value === MEDICATION_TYPE
        ? MEDICATION_CODE
        : value === PROBLEM_LIST_TYPE
          ? PROBLEM_LIST_CODE
          : '';
    setVariable(variable);
    setVariableType(value);
  };

  useEffect(() => {
    if (!variableType) return;
    setAmount(DEFAULT_AMOUNT_BY_TYPES[variableType]);
  }, [variableType]);

  useEffect(() => {
    if (![PROBLEM_LIST_TYPE, DEMOGRAPHICS_TYPE].includes(variableType)) {
      if (!amount || amount > MAXIMUM_AMOUNT_BY_TYPES[variableType]) {
        onDisabledChange(true);
        return;
      }
      if (
        (!customDay || customDay > 1095) &&
        [NOTE_CUSTOM_RANGE_CODE, LAB_DATA_CUSTOM_RANGE_CODE, VITAL_SIGN_CUSTOM_RANGE_CODE].includes(
          variable
        )
      ) {
        onDisabledChange(true);
        return;
      }
    }
    let option = suggestions.find((option) => option.code === variable);
    if (!option) {
      onDisabledChange(true);
      return;
    }
    onChange({
      ...option,
      hasUnsignedNote: variableType === 'note' ? hasUnsignedNote : undefined,
      past24HoursExcluded: variableType === 'note' ? past24HoursExcluded : undefined,
      days: customDay,
      amount,
    });
    onDisabledChange(false);
  }, [
    suggestions,
    variable,
    customDay,
    variableType,
    amount,
    hasUnsignedNote,
    past24HoursExcluded,
  ]);

  return (
    <>
      <DropdownSingle
        value={variableType}
        options={filteredVariableTypes}
        placeholder='Select EHR Data'
        onChange={(value) => {
          setCustomDay(undefined);
          changeVariableType(value as string);
        }}
      />
      {variableType !== MEDICATION_TYPE && variableType !== PROBLEM_LIST_TYPE && (
        <ComboBox
          selectedValue={variable}
          options={variableOptions}
          disabled={!variableType}
          onChange={(value) => {
            setCustomDay(undefined);
            setVariable(value as string);
          }}
        />
      )}
      {!!variableType && ![PROBLEM_LIST_TYPE, DEMOGRAPHICS_TYPE].includes(variableType) && (
        <StaffOnlyWrapper type={StaffOnlyBadgeType.BETA} y={-10}>
          <NumberInput
            prefixLabel='Maximum'
            postfixLabel={READABLE_EHR_TYPES[variableType]}
            value={amount}
            onChange={(amount) => setAmount(amount)}
            maximumValue={MAXIMUM_AMOUNT_BY_TYPES[variableType]}
            placeholder='number'
            errorLabel={`*The maximum number of ${READABLE_EHR_TYPES[variableType]} is ${MAXIMUM_AMOUNT_BY_TYPES[variableType]}.`}
          />
        </StaffOnlyWrapper>
      )}
      {[
        NOTE_CUSTOM_RANGE_CODE,
        LAB_DATA_CUSTOM_RANGE_CODE,
        VITAL_SIGN_CUSTOM_RANGE_CODE,
        RADIOLOGY_REPORT_CUSTOM_RANGE_CODE,
      ].includes(variable ?? '') && (
        <NumberInput
          prefixLabel='Last'
          postfixLabel='days'
          value={customDay}
          onChange={(amount) => setCustomDay(amount)}
          maximumValue={1095}
          placeholder='number'
          errorLabel={`*The Maximum number of days is 1095.`}
        />
      )}
      {variableType === 'note' && (
        <>
          <div className='mt-4 flex'>
            <Label className='flex cursor-pointer items-center space-x-[8px]'>
              <Checkbox
                checked={hasUnsignedNote}
                onChange={(e) => setHasUnsignedNote(e.currentTarget.checked)}
              />
              <span className='text-sm text-gray-900'>Include unsigned notes</span>
            </Label>
          </div>
          <div className='flex'>
            <Label className='flex cursor-pointer items-center space-x-[8px]'>
              <Checkbox
                checked={past24HoursExcluded}
                onChange={(e) => setPast24HoursExcluded(e.currentTarget.checked)}
              />
              <span className='text-sm text-gray-900'>Exclude &lt; 24 hours notes</span>
            </Label>
          </div>
        </>
      )}
    </>
  );
};

interface EHRTypes {
  id: SuggestionTypes;
  title: string;
}

const EHR_TYPES: EHRTypes[] = [
  {
    id: NOTE_TYPE,
    title: 'Notes',
  },
  {
    id: LAB_DATA_TYPE,
    title: 'Lab Results',
  },
  {
    id: VITAL_SIGN_TYPE,
    title: 'Vital Signs',
  },
  {
    id: MEDICATION_TYPE,
    title: 'Medications',
  },
  {
    id: PROBLEM_LIST_TYPE,
    title: 'Problem List',
  },
  {
    id: DEMOGRAPHICS_TYPE,
    title: 'Demographics',
  },
  {
    id: RADIOLOGY_REPORT_TYPE,
    title: 'Radiology Reports',
  },
];

interface NumberInputProps {
  prefixLabel: string;
  postfixLabel: string;
  placeholder?: string;
  value?: number;
  onChange: (value: number) => void;
  maximumValue?: number;
  errorLabel: string;
}

export const NumberInput = ({
  prefixLabel,
  postfixLabel,
  placeholder,
  value,
  onChange,
  maximumValue,
  errorLabel,
}: NumberInputProps) => {
  const maximumValueExceeded = !!value && !!maximumValue && value > maximumValue;
  return (
    <>
      <div className='flex h-[40px] items-end justify-between gap-[8px]'>
        <Body1 className='text-gray-700'>{prefixLabel}</Body1>
        <div className='flex gap-[8px]'>
          <input
            type='number'
            className={twMerge(
              'max-w-[164px] flex-1 border-b !border-gray-300 text-right',
              maximumValueExceeded && '!border-error',
              'mb-0 !h-6 focus:!shadow-none' //due to material UI
            )}
            placeholder={placeholder}
            value={value}
            onChange={(e) => onChange(e.target.valueAsNumber)}
          />
          <Body1 className='min-w-[44px] whitespace-nowrap text-right text-gray-700'>
            {postfixLabel}
          </Body1>
        </div>
      </div>
      {maximumValueExceeded && <Caption2 className='text-right text-error'>{errorLabel}</Caption2>}
    </>
  );
};
